import { FieldProps } from "@rjsf/utils";
import JfsTextField from "../../../input/jfsTextField/jfsTextField";
import { handleInputValue } from "../../../../../helpers";

const handleWheel = event => {
  if (document.activeElement === event.target) {
    event.target.blur(); // Removes focus from the input field
  }
};
function JfsNumber({ onChange, ...otherProps }: JfsNumberProps) {
  const onTextChange = (val: string | number) => {
    if (typeof val === "string") {
      val = parseFloat(val);
    }
    onChange(handleInputValue(val));
  };

  return (
    <JfsTextField
      id={otherProps.idSchema.$id}
      label={otherProps.idSchema.$id}
      value={otherProps.formData || ""}
      options={otherProps.options}
      step={otherProps.schema.format === "dollar" ? ".01" : undefined}
      type={otherProps.schema.type}
      onChange={(val: string) => onTextChange(val)}
      onWheel={handleWheel}
      {...otherProps}
    />
  );
}

interface JfsNumberProps extends FieldProps {}

JfsNumber.defaultProps = {};

export default JfsNumber;
