import { useHistory } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import { Icon, Link, Typography } from "../../components/util";
import * as Styled from "./styles.css";

interface AuthSidebarProps {
  backLink?: any;
  isSidebarVisible: boolean;
}

export const AuthSidebarBroker = ({
  backLink,
  isSidebarVisible,
}: AuthSidebarProps) => {
  const history = useHistory();

  return (
    <Styled.Sidebar $isSidebarVisible={isSidebarVisible}>
      <Styled.Back $isSidebarVisible={isSidebarVisible}>
        {backLink &&
          (backLink ? (
            backLink
          ) : (
            <Link href={"#"} onClick={history.goBack}>
              <Icon color="accent" icon="arrow-left" size="sm" />
              Back
            </Link>
          ))}
      </Styled.Back>
      {isSidebarVisible && (
        <Hidden lgDown>
          <Styled.Container>
            <a
              href="https://care.safetyculture.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Styled.Logo />
            </a>

            <Typography variant="displaySmall">
              You know your clients. <br />
              We know risk.
            </Typography>
            <br />

            <Typography element={"p"}>
              We're here to support you in providing more than just insurance
              for your clients — we help you empower them to improve and protect
              their business.
            </Typography>

            <Styled.List>
              <Styled.ListItem
                icon="people"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="aside" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Fast and reliable support
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    whenever you need it
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="globe"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="aside" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Local knowledge,
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    global backing
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="star"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="span" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Trusted experts
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    in risk management and insurance
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="graph"
                iconAlignment="top"
                iconShade="strongest"
                color="surface"
              >
                <Typography element="span" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    In-depth safety ratings
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    aligned with industry standards
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="verified"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="span" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Proactive safety
                  </Styled.ListItemBoldText>
                  <Styled.ListItemLightText>
                    checks powered by the SafetyCulture app
                  </Styled.ListItemLightText>
                </Typography>
              </Styled.ListItem>
            </Styled.List>
          </Styled.Container>
        </Hidden>
      )}
    </Styled.Sidebar>
  );
};
