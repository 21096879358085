import * as React from "react";
import {
  BadgeSize,
  BadgeVariant,
} from "@safetyculture/sc-web-ui/cjs/packages/react/badge/types";
import { Badge } from "@safetyculture/sc-web-ui";
import { AccountStatus } from "../../../services/auth/auth.service";

type Props = { status: AccountStatus; size?: BadgeSize };

export const AccountStatusBadge: React.FC<Props> = ({
  status = AccountStatus.Unverified,
  size = "medium",
}) => {
  return (
    <Badge size={size} variant={variant[status]}>
      {text[status]}
    </Badge>
  );
};

const text: { [key in AccountStatus]: string } = {
  unverified: "Unverified",
  active: "Active",
  archived: "Archived",
};

const variant: { [key in AccountStatus]: BadgeVariant } = {
  active: "positive",
  unverified: "negative",
  archived: "default",
};
