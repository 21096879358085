import { useQueryClient } from "react-query";
import { IconButton } from "../../../util";
import toastEvent from "../../../util/toast/toast.event";
import AdminService from "../../../../services/admin/admin.service";
import * as Styled from "./styles.css";
import { AccountStatus } from "../../../../services/auth/auth.service";
import SelectUserAccountStatus from "../../inputs/accountStatus/selectUserAccountStatus";

interface iEditUserAccountStatusProps {
  field: any;
  onClose: () => void;
  onSubmitRefresh: () => void;
  toggleEditing: () => void;
  record: Record<string, any>;
  recordType: string;
}

const EditUserAccountStatus = ({
  field,
  onSubmitRefresh,
  record: user,
  recordType,
  toggleEditing,
}: iEditUserAccountStatusProps) => {
  const queryClient = useQueryClient();

  const editDataInit = {
    user_id: user.id,
    account_status: user.account_status,
  };

  const setErrors = (editDetails: any): Record<string, boolean> => {
    const errors: Record<string, boolean> = {};
    for (const key in editDetails) {
      if (key === field) {
        errors[key] = true;
      } else {
        errors[key] = false;
      }
    }
    return errors;
  };

  const editErrorsInit = setErrors(editDataInit);

  const handleSubmit = async (formData: {
    user_id: string;
    account_status: AccountStatus;
  }) => {
    await AdminService.updateUserAccountStatus(formData);
    queryClient.invalidateQueries("admin-user-details");
  };

  return (
    <>
      <Styled.InlineForm
        inline
        onSubmit={{
          service: handleSubmit,
          onSuccess: () => {
            onSubmitRefresh();
            toastEvent.success(`${recordType} updated`);
            toggleEditing();
          },
        }}
        validationErrors={[editErrorsInit]}
        validationFields={[editDataInit]}
        submitInline
        submitAlign="right"
        submitStartDisabled
        submitText="Save"
      >
        <SelectUserAccountStatus id="account_status" />
      </Styled.InlineForm>
      <IconButton iconShade="weak" icon="x" onClick={() => toggleEditing()} />
    </>
  );
};

export default EditUserAccountStatus;
