import { useHistory } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import { Avatar, Grid, Icon, Link, Typography } from "../../components/util";
import { BlueFit } from "../../assets/images";
import * as Styled from "./styles.css";

interface AuthSidebarProps {
  backLink?: any;
  isSidebarVisible: boolean;
}

export const AuthSidebarPolicyHolder = ({
  backLink,
  isSidebarVisible,
}: AuthSidebarProps) => {
  const history = useHistory();

  return (
    <Styled.Sidebar $isSidebarVisible={isSidebarVisible}>
      <Styled.Back $isSidebarVisible={isSidebarVisible}>
        {backLink &&
          (backLink ? (
            backLink
          ) : (
            <Link href={"#"} onClick={history.goBack}>
              <Icon color="accent" icon="arrow-left" size="sm" />
              Back
            </Link>
          ))}
      </Styled.Back>
      {isSidebarVisible && (
        <Hidden lgDown>
          <Styled.Container>
            <a
              href={"https://care.safetyculture.com/"}
              target="_blank"
              rel="noreferrer"
            >
              <Styled.Logo />
            </a>
            <Typography element="article" variant="displaySmall">
              Thank you for being insured with SafetyCulture Care.
            </Typography>
            <br />
            <Typography element={"p"} variant="body1">
              We’re here to help you improve your business, not just insure it.
            </Typography>
            <br />
            <Typography element={"p"} variant="body1">
              Start using SafetyCulture, your suite of risk management tools.
              It’s an easy to use mobile app that can help you and your team:
            </Typography>
            <Styled.List>
              <Styled.ListItem
                icon="verified"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="aside" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Protect assets
                  </Styled.ListItemBoldText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="verified"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="aside" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Reduce incidents
                  </Styled.ListItemBoldText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="verified"
                iconAlignment="top"
                iconShade="strongest"
              >
                <Typography element="aside" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Prevent stock loss
                  </Styled.ListItemBoldText>
                </Typography>
              </Styled.ListItem>
              <Styled.ListItem
                icon="verified"
                iconAlignment="top"
                iconShade="strongest"
                color="surface"
              >
                <Typography element="aside" variant="subtitle1">
                  <Styled.ListItemBoldText>
                    Ensure compliance
                  </Styled.ListItemBoldText>
                </Typography>
              </Styled.ListItem>
            </Styled.List>

            <Typography element={"p"} variant="body1">
              SafetyCulture is trusted by 85,000+ teams to work safely, meet
              higher standards and improve every day.
            </Typography>
          </Styled.Container>
        </Hidden>
      )}
    </Styled.Sidebar>
  );
};
