import { useContext } from "react";
import { AccountStatus } from "../../../services/auth/auth.service";
import AuthContext from "./../../../contexts/authContext";
import { Grid, TabPanel, Tabs, Typography } from "./../../util";
import OnboardingTab from "./onboardingTab";
import * as Styled from "./styles.css";

const OnboardingUnverified = () => {
  const auth = useContext(AuthContext);

  const isAccountStatusActive =
    auth.user?.account_status === AccountStatus.Active ? true : false;

  return (
    <Styled.OnboardingContainer corners="rounded" outlined>
      <Styled.Header title="Complete these steps to start quoting"></Styled.Header>
      <Grid container>
        <Tabs
          id="newuser"
          orientation="vertical"
          variant="contained"
          tabs={[
            <OnboardingTab
              complete={isAccountStatusActive}
              icon="iauditor"
              id="review"
              label="Application under review"
              onComplete={["disabled", "restricted"]}
            />,
          ]}
        >
          <TabPanel id="review">
            <Styled.PanelHeading element="h3" paragraph variant="heading">
              We're reviewing your application
            </Styled.PanelHeading>
            <Styled.PanelBodyText paragraph>
              <Typography paragraph>
                Our team are currently reviewing your application and will
                respond within 48 hours. In order to operate with SafetyCulture
                Care, all brokers must hold a valid broker agreement.
              </Typography>
              <Typography paragraph>
                Once we have confirmed you’re eligibility, you will receive a
                email notification that you’re ready to quote.
              </Typography>
            </Styled.PanelBodyText>
          </TabPanel>
        </Tabs>
      </Grid>
    </Styled.OnboardingContainer>
  );
};

export default OnboardingUnverified;
