import * as React from "react";
import { FormLabel, Select, Typography } from "@safetyculture/sc-web-ui";
import {
  IOption,
  ObjectOrUndefined,
} from "@safetyculture/sc-web-ui/cjs/packages/react/select/types";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  Validate,
  ValidateResult,
} from "react-hook-form";
import { Select_Validation_Rule } from "../_validation_rules";
import { FormSpacing } from "../form.css";
import { SelectWrapper } from "./SelectWrapper";
import styled from "styled-components";

export type SelectOption<T extends ObjectOrUndefined = undefined> =
  IOption<T> & {
    description?: string;
  };

export type SelectWithValidationProps<
  T extends FieldValues,
  J extends ObjectOrUndefined,
> = {
  name: keyof T; // Only map to main field names, not nested fields
  control: Control<T>; // returned from useForm hook
  data?: SelectOption<J>[];
  label?: string;
  rules?: {
    [key in Select_Validation_Rule]?: (
      value: SelectOption | SelectOption[],
    ) => ValidateResult;
  };
  multiple?: boolean;
  addOption?: (option: SelectOption) => void;
  placeholder?: string;
  /**
   * @searchable enable filtering options and retrieving search termn
   * A boolean value is enough to filter through current options
   * Provide a callback to get the search term
   */
  searchable?: ((search: string) => void) | boolean;
  /**
   * @default false set to true to display a loading spinner in the select
   */
  isLoading?: boolean;
  /**
   * @default {top: 24}
   */
  margin?: { top?: number; bottom?: number; left?: number; right?: number };

  /**
   * Provide a override to the Select component's default filter function so we can filter on meta properties.
   * WARNING: Select component automatically filters on label and value only. It ignores description.
   * To disable the component's filter alltogether you can pass `filterFn={() => true}` eg. backend driven filtering
   */
  filterFn?: (option: SelectOption) => boolean;
};

/**
 * @SelectWithValidation
 *
 * Validation value format: either a string or a SelectOption object.
 * Default value are set via useForm hook, under `defaultValues`.
 *
 */

export function SelectWithValidation<
  T extends FieldValues,
  J extends ObjectOrUndefined,
>({
  name,
  control,
  rules,
  data,
  multiple = false,
  addOption,
  placeholder,
  searchable,
  label,
  margin = { top: 24 },
  isLoading = false,
  filterFn,
}: SelectWithValidationProps<T, J>) {
  const field_name = name as Path<T>;
  const search = React.useRef<string>("");

  const handleSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      search.current = e.target.value;
      if (searchable && typeof searchable === "function") {
        searchable(search.current);
      }
    },
    [searchable],
  );

  const resetSearch = React.useCallback(() => {
    search.current = "";
    if (searchable && typeof searchable === "function") {
      searchable(search.current);
    }
  }, [searchable]);

  return (
    <FormSpacing
      $marginTop={margin.top}
      $marginBottom={margin.bottom}
      $marginLeft={margin.left}
      $marginRight={margin.right}
    >
      <Controller
        name={field_name}
        control={control}
        rules={{ validate: rules as Validate<PathValue<T, Path<T>>, T> }}
        render={({ field, fieldState }) => {
          return (
            <div tabIndex={0} ref={field.ref}>
              <FormLabel
                htmlFor={`select-${field_name}`}
                type={rules?.required ? "required" : "optional"}
              >
                <Typography variant="titleSmall">{label}</Typography>
              </FormLabel>
              <FormSpacing
                $marginTop={4}
                $marginBottom={4}
                id={`select-${field_name}`}
              >
                <SelectWrapper
                  field={field}
                  multiple={multiple}
                  rules={rules}
                  resetSearch={resetSearch}
                >
                  <Select.Trigger>
                    <Select.Trigger.TypeableLayout
                      width="full"
                      id={field_name}
                      placeholder={placeholder}
                      error={!!fieldState.error}
                      onChange={handleSearch}
                      filterFn={filterFn}
                    />
                  </Select.Trigger>
                  <StyledSelectContent matchTriggerWidth={true}>
                    {isLoading && <StyledSelectContent.LoadingState />}
                    {data?.map((item, i) => (
                      <Select.Item
                        key={`${field_name}-${i}`}
                        value={item.value}
                        label={item.label}
                      >
                        <Select.Item.BaseLayout
                          description={item.description}
                        />
                      </Select.Item>
                    ))}
                    {!!addOption && (
                      <StyledSelectContent.AddOptionButton
                        onClick={label => {
                          const option = { label, value: label };
                          addOption(option);
                          return option;
                        }}
                      />
                    )}
                    {(!data || !data?.length) && !isLoading && (
                      <StyledSelectContent.NoResultsState display={true} />
                    )}
                  </StyledSelectContent>
                </SelectWrapper>
                {fieldState.error?.message && (
                  <Typography
                    color="negative.text.default"
                    component="span"
                    variant="bodySmall"
                  >
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormSpacing>
            </div>
          );
        }}
      />
    </FormSpacing>
  );
}

const StyledSelectContent = styled(Select.Content)`
  z-index: 10001;
`;
