import { useState } from "react";
import toolkitService, {
  Toolkit,
} from "../../../../services/toolkits/toolkits.service";
import { useToolkitSelectOptions } from "../../../../hooks/queries/select_options/useToolkitSelectOptions.query";
import { Button, Dialog } from "@safetyculture/sc-web-ui";
import {
  SelectOption,
  SelectWithValidation,
} from "../../../../components/form/SelectWithValidation";
import { useForm } from "react-hook-form";
import { validation_rules } from "../../../../components/form/_validation_rules";
import toastEvent from "../../../../components/util/toast/toast.event";
import { UseQueryResult } from "react-query";

export type Props = {
  orgId: string;
  onCancel: () => void;
  onToolkitAdded: () => Promise<void>;
};

export const AddToolkitToOrgModal: React.FC<Props> = ({
  orgId,
  onCancel,
  onToolkitAdded,
}) => {
  const [toolkit_search_term, setToolkitSearchTerm] = useState("");
  const toolkit_options: UseQueryResult<SelectOption<Toolkit>[]> =
    useToolkitSelectOptions({ toolkit_search_term });

  const form = useForm<AddToolkitFields>({ mode: "all" });

  const submitAddToolkitToOrg = form.handleSubmit(
    async (data: AddToolkitFields) => {
      try {
        await toolkitService.addToolkitToOrg(orgId, data.toolkit.value);
        onToolkitAdded();
      } catch (error) {}
    },
  );

  return (
    <Dialog
      isOpen={true}
      onClose={onCancel}
      showCloseButton={true}
      size="medium"
      zIndex={10000}
    >
      <Dialog.Header>
        <Dialog.Title>Add toolkit</Dialog.Title>
        <Dialog.Description>
          <SelectWithValidation
            name="toolkit"
            control={form.control}
            data={toolkit_options.data}
            isLoading={toolkit_options.isLoading}
            label="Toolkit"
            placeholder="Toolkit code"
            rules={{
              required: validation_rules.select.required(
                "Please select a toolkit.",
              ),
            }}
            searchable={setToolkitSearchTerm}
            filterFn={() => true} // disable static filtering
          />
        </Dialog.Description>
      </Dialog.Header>
      <Dialog.Footer>
        <Button
          onClick={submitAddToolkitToOrg}
          isLoading={form.formState.isSubmitting}
        >
          Add toolkit
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

type AddToolkitFields = {
  toolkit: SelectOption<Toolkit>;
};
