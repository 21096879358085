import { useContext } from "react";
import { AuthContext } from "../../../../contexts";
import { Select } from "../../../util";
import Joi from "joi";
import { AccountStatus } from "../../../../services/auth/auth.service";

const userAccountStatusValidationSchema = Joi.string().required();

const getAccountStatusOptions = (): { title: string; value: string }[] => {
  const allAccountStatuses = Object.values(AccountStatus);
  const result = allAccountStatuses.map(accountStatus => {
    return { title: accountStatus, value: accountStatus };
  });
  return result;
};

let accountStatusOptions = getAccountStatusOptions();

const SelectUserAccountStatus = ({
  disabled,
  id,
  label,
  value,
}: iSelectUserAccountStatusProps) => {
  return (
    <Select
      id={id}
      disabled={disabled}
      fullWidth
      placeholder="Select user account status..."
      size={label ? "lg" : undefined}
      label={label}
      validationOnChange
      validationType={userAccountStatusValidationSchema}
      options={accountStatusOptions}
      grow
      value={value}
    />
  );
};

interface iSelectUserAccountStatusProps {
  id: string;
  label?: string;
  value?: any;
  disabled?: boolean;
}

SelectUserAccountStatus.defaultProps = {};

export default SelectUserAccountStatus;
