import { InlineBanner } from "@safetyculture/sc-web-ui";
import * as React from "react";
import { useFlag } from "@unleash/proxy-client-react";

/**
 * Text             Powered by AWS param store
 * ON/OFF toggle    Powered by unleash operational flag
 */
export const MaintenanceInfoBanner: React.FC = () => {
  const { isVisible, closeBannerForSession } = React.useContext(
    MaintenanceBannerContext,
  );

  return (
    (isVisible && (
      <div style={{ marginBottom: 24 }} data-testid="maintenance-banner">
        <InlineBanner
          status="warning"
          closeProps={{
            onClick: closeBannerForSession,
          }}
        >
          {process.env.REACT_APP_MAINTENANCE_BANNER_TEXT}
        </InlineBanner>
      </div>
    )) ||
    null
  );
};

/**
 * Context allows us to hide the banner for the session wherever it is used in the code
 */
export const MaintenanceBannerContext = React.createContext<{
  isVisible: boolean;
  closeBannerForSession: () => void;
}>({
  isVisible: true,
  closeBannerForSession: () => {},
});

export const MaintenanceInfoBannerProvider: React.FC<{
  children: React.ReactNode;
  is_enabled_override?: boolean;
}> = ({ children, is_enabled_override }) => {
  const is_enabled = useFlag("portal.maintenance_banner_enabled");
  const [isVisible, setIsVisible] = React.useState(
    is_enabled_override || is_enabled,
  );

  return (
    <MaintenanceBannerContext.Provider
      value={{
        isVisible,
        closeBannerForSession: () => setIsVisible(false),
      }}
    >
      {children}
    </MaintenanceBannerContext.Provider>
  );
};
