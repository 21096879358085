import { Button, Dialog, Typography } from "@safetyculture/sc-web-ui";
import {
  SelectOption,
  SelectWithValidation,
} from "../../../components/form/SelectWithValidation";
import { useForm } from "react-hook-form";
import { IOrganisation } from "../../../services/admin/admin.service";
import { useState } from "react";
import { useOrgSelectOptions } from "../../../hooks/queries/select_options/useOrgSelectOptions.query";
import PolicyService from "../../../services/policy/policy.service";
import { validation_rules } from "../../../components/form/_validation_rules";

type OrgModalProps = {
  data?: OrgInfo;
  onClose: () => void;
  onConfirm?: () => void;
  modalType: tOrgModalType;
};

export interface OrgInfo {
  policy_id?: string;
  org_id?: string;
  org_name?: string;
  old_org_id?: string;
}

export const PolicyOrgModal = ({
  data,
  onClose,
  onConfirm,
  modalType,
}: OrgModalProps) => {
  const form = useForm<PolicyOrgIdForm>({ mode: "all" });
  const [org_search_term, setOrgSearchTerm] = useState<string>("");
  const org_select_options = useOrgSelectOptions({
    org_search_term,
    filtered_org_ids: data?.org_id ? [data?.org_id] : [],
  });

  const handleSubmit = async (formData: PolicyOrgIdForm): Promise<void> => {
    if (modalType) {
      try {
        const submit_data: OrgInfo = {
          policy_id: data?.policy_id,
          org_id: modalType === "Remove" ? data?.org_id : formData?.org.value,
          old_org_id:
            modalType?.toLocaleLowerCase() === "update" ? data?.org_id : "",
        };
        await PolicyService.policyOrgPivot(
          modalType?.toLowerCase(),
          submit_data,
        );

        onConfirm && onConfirm();
        onClose();
      } catch (error: any) {
        if (error?.response?.data?.error) {
          throw new Error(error?.response.data?.error);
        } else {
          throw error;
        }
      }
    }
  };

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      zIndex={10000}
      size="large"
      showCloseButton={true}
    >
      <Dialog.Header>
        <Dialog.Title>{`${modalType} Organisation`}</Dialog.Title>
      </Dialog.Header>
      {modalType === "Remove" ? (
        <Dialog.Body>
          The policy will not be associated with this organisation anymore.
        </Dialog.Body>
      ) : (
        <Dialog.Body>
          <Typography variant="bodyMedium">
            Information related to the policy will be accessible to this
            organisation.
          </Typography>
          <SelectWithValidation
            control={form.control}
            multiple={false}
            rules={{
              required: validation_rules.select.required(
                "Organisation is required",
              ),
            }}
            label="Organisation"
            name="org"
            data={org_select_options.data}
            isLoading={org_select_options.isLoading}
            searchable={setOrgSearchTerm}
            margin={{ top: 24 }}
            filterFn={() => true} // disable static filtering
          />
        </Dialog.Body>
      )}
      <Dialog.Footer>
        <Button
          onClick={form.handleSubmit(handleSubmit)}
          variant="primary"
          disabled={org_select_options.isLoading}
          isLoading={form.formState.isSubmitting}
        >
          {modalType}
        </Button>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export type tOrgModalType = "Add" | "Update" | "Remove" | undefined;

type PolicyOrgIdForm = { org: SelectOption<IOrganisation> };
