import { useState } from "react";
import { useOrgSelectOptions } from "../../../hooks/queries/select_options/useOrgSelectOptions.query";
import UserService from "../../../services/user/user.service";
import { Button, Dialog, Typography } from "@safetyculture/sc-web-ui";
import { useForm } from "react-hook-form";
import {
  SelectOption,
  SelectWithValidation,
} from "../../../components/form/SelectWithValidation";
import { IOrganisation } from "../../../services/admin/admin.service";
import { validation_rules } from "../../../components/form/_validation_rules";

interface UserOrgModalProps {
  data?: OrgInfo;
  onClose: () => void;
  onConfirm?: () => void;
  modalType: tOrgModalType;
}

export const UserOrgModal = ({
  data,
  onClose,
  onConfirm,
  modalType,
}: UserOrgModalProps) => {
  const form = useForm<OrgIdForm>({ mode: "all" });
  const [org_search_term, setOrgSearchTerm] = useState<string>("");
  const org_select_options = useOrgSelectOptions({
    org_search_term,
    filtered_org_ids: data?.org_id ? [data?.org_id] : [],
  });

  const handleSubmit = async (formData: OrgIdForm): Promise<void> => {
    switch (modalType) {
      case "Add":
        await UserService.addUserOrg({
          org_id: formData.org.value,
          user_id: data?.user_id || "",
          old_org_id: data?.old_org_id || undefined,
        });
        break;
      case "Remove":
        await UserService.removeUserOrg({
          user_id: data?.user_id || "",
          org_id: data?.org_id || "",
        });
        break;
      case "Update":
        await UserService.updateUserOrg({
          user_id: data?.user_id || "",
          org_id: formData.org.value,
          old_org_id: data?.org_id || undefined,
        });
        break;
    }
    onConfirm && onConfirm();
    onClose();
  };

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      zIndex={10000}
      size="large"
      showCloseButton={true}
    >
      <Dialog.Header>
        <Dialog.Title>{`${modalType} organisation`}</Dialog.Title>
      </Dialog.Header>
      {modalType === "Remove" ? (
        <Dialog.Body>
          Organisation details will not be shared with this user anymore.
        </Dialog.Body>
      ) : (
        <Dialog.Body>
          <Typography variant="bodyMedium">
            The user will only be able to access information related to the new
            organisation.
          </Typography>
          <SelectWithValidation
            control={form.control}
            multiple={false}
            rules={{
              required: validation_rules.select.required(
                "Organisation is required",
              ),
            }}
            label="Organisation"
            name="org"
            data={org_select_options.data}
            isLoading={org_select_options.isLoading}
            searchable={setOrgSearchTerm}
            margin={{ top: 24 }}
            filterFn={() => true} // disable static filtering
          />
        </Dialog.Body>
      )}
      <Dialog.Footer>
        <Button
          onClick={form.handleSubmit(handleSubmit)}
          variant="primary"
          disabled={org_select_options.isLoading}
          isLoading={form.formState.isSubmitting}
        >
          {modalType}
        </Button>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export type tOrgModalType = "Add" | "Update" | "Remove" | undefined;

export type OrgInfo = {
  user_id: string;
  org_id?: string;
  old_org_id?: string;
};

type OrgIdForm = { org: SelectOption<IOrganisation> };
