import { useState, useContext, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext, LocationContext } from "../../../../contexts";
import authService, {
  SignUpArgs,
} from "../../../../services/auth/auth.service";
import invitationService from "../../../../services/invitation/invitation.service";
import { Form, Link, Typography } from "../../../../components/util";
import AuthContainer from "../../authContainer";
import PersonalInfo, { PersonalData } from "./personalInfo";
import { useAsyncEffect } from "../../../../hooks/useAsyncEffect";

const SignUp = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [signed_up, setSignedUp] = useState<boolean>(false);

  const { urlParams } = useContext(LocationContext);

  const visitor_role = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location?.search);
    return urlSearchParams.get("visitor_role") || "broker";
  }, []);

  // TODO: refactor into automatic param reading by form based on field ID
  const formPrefill = useCallback(
    (
      urlParams: Record<string, any> | undefined,
      formData?: Record<string, any>[],
      formErrors?: Record<string, boolean>[],
    ): Record<string, any>[] | Record<string, boolean>[] | undefined => {
      if (formData && authService.isLoggedIn()) {
        formData[0].isExistingAuth0User = true;
      }
      if (formData && urlParams?.email) {
        formData[0].email = urlParams.email;
      }
      if (formErrors && urlParams?.email) {
        formErrors[0].email = false;
      }
      if (formData && urlParams?.first_name) {
        formData[0].firstName = urlParams.first_name;
      }
      if (formErrors && urlParams?.first_name) {
        formErrors[0].firstName = false;
      }
      if (formData && urlParams?.last_name) {
        formData[0].lastName = urlParams.last_name;
      }
      if (formErrors && urlParams?.last_name) {
        formErrors[0].lastName = false;
      }

      return formErrors ? formErrors : formData;
    },
    [],
  );

  const signUpService = async (data: PersonalData) => {
    const signUpData: SignUpArgs = {
      ...data,
      industry: "",
      phoneNumber: "",
      isBroker:
        urlParams?.role === "broker" || visitor_role === "broker"
          ? true
          : false,
      companyName:
        urlParams?.orgName ||
        data.firstName + " " + data.lastName + " Organisation",
      organisationSize: "",
      invitation_id: urlParams?.invId || undefined,
    };

    const loggedIn = await authService.signUp(signUpData);

    /*
    loggedIn is used to check if the user is logged in or not after signup.
    If loggedIn update auth context else redirect to login page.
    */
    if (loggedIn) {
      const user = await authService.getMittiUser();
      auth.updateUser(user);
      setSignedUp(true);
    } else {
      history.push("/login");
    }
  };

  useAsyncEffect(async () => {
    if (auth.user) {
      signed_up ? history.push("/") : history.push("/already-logged-in");
    }
    if (!signed_up && urlParams?.invite === "true") {
      try {
        const inviteStatus = await invitationService.getInvitationStatus(
          urlParams.invId as string,
        );
        if (inviteStatus === "cancelled" || inviteStatus === "claimed") {
          history.push(`/auth/invitation-${inviteStatus}`);
        } else {
          const scim = await authService.getScimUserDetails();
          if (urlParams?.email === scim.userName) {
            const queryParams = new URLSearchParams(urlParams).toString();
            history.push(
              `/link-sc-user-to-care?${queryParams}&isExistingAuth0User=true`,
            );
          } else if (urlParams?.invId && urlParams?.email !== scim.userName) {
            history.push(`/auth/invitation-email`);
          }
        }
      } catch (error) {}
    }
  }, [signed_up, auth.user, history, urlParams]);

  let validationErrors: any[] = [personalErrorsInit];
  let validationFields: any[] = [personalDataInit];

  return (
    <AuthContainer isSidebarVisible backLink={false} preventRedirect>
      <Form
        onSubmit={{
          service: signUpService,
        }}
        validationErrors={validationErrors}
        validationFields={validationFields}
        prefill={formPrefill}
        submitText={"Create account"}
      >
        <PersonalInfo
          footer={
            <Typography variant="body2" styled={{ text: { align: "center" } }}>
              Already have an account?{" "}
              <Link href="/login" router>
                Sign in
              </Link>
            </Typography>
          }
          submitText="Create account"
          subtitle={
            visitor_role === "broker"
              ? "Join thousands of other brokers helping to improve their client’s business, not just insure it."
              : "Unlock proactive risk management by creating your SafetyCulture account."
          }
          title={
            visitor_role === "broker"
              ? "Are you a broker? Start here"
              : "Get started"
          }
        />
      </Form>
    </AuthContainer>
  );
};

const personalDataInit: Record<string, string | boolean> = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  isExistingAuth0User: false,
  has_consent: false,
};

const personalErrorsInit: Record<string, boolean> = {
  firstName: true,
  lastName: true,
  email: true,
  password: true,
  has_consent: false,
};

export default SignUp;
