import { useContext } from "react";
import { AuthContext, SidebarContextProvider } from "./../../../contexts";
import { RouteInterface } from "./../../../routes/routes";
import { Footer, AppBar, Main, Sidebar } from "./../../";
import * as Styled from "./styles.css";
import { MaintenanceInfoBanner } from "../../content/maintenance/MaintenanceInfoBanner";

const Framing = ({ children, route, isProtectedRoute }: LayoutProps) => {
  const auth = useContext(AuthContext);

  return (
    <SidebarContextProvider>
      {auth.user && route.roles && <AppBar />}
      {auth.user && route.roles && <Sidebar />}
      <Main route={route}>
        <Styled.Article
          element="article"
          $isAppFramed={!auth.user || !route.roles}
          $pageType={route.pageType}
        >
          {!!isProtectedRoute && <MaintenanceInfoBanner />}
          {children}
        </Styled.Article>

        {auth.user && route.roles && <Footer />}
      </Main>
    </SidebarContextProvider>
  );
};

type LayoutProps = {
  children?: any;
  route: RouteInterface;
  routes: RouteInterface[];
  isProtectedRoute?: boolean;
};

export default Framing;
